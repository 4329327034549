import React, { Component } from "react";
import * as cookies from "../cookie.js";

import logo from './assets/svg/ult-secondary-variant-lock-up-fr.svg';
import jacket from './assets/img/Header.png';
import facebook from './assets/svg/facebook.svg';
import twitter from './assets/svg/twitter.svg';
import instagram from './assets/svg/instagram.svg';
import { Link } from "react-router-dom";
export default class PinPage extends Component {
    constructor(props) {
        super(props);

        require("./app.css");

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = { value: '' };

    }

    componentDidMount() {

        cookies.checkCookie();

    }

    handleChange(event) {
        this.setState({ value: event.target.value });
    }
    handleSubmit(event) {
        // alert((this.state.value.toUpperCase()));
        window.location = './SkillPagefr/' + this.state.value.toUpperCase();

        event.preventDefault();
    }

    render() {

        return (

            <div className="app app--enterPin">

                <header className="navigationMenu backgroundColorWhite">

                    <div className="navigationMenuContents">

                        <a href="/" className="navigationMenu__logo"><img src={logo} alt="logo" /></a>

                        <div className="langSwitcher langSwitcher--fr">
                            <a href="PinPage" className="langSwitcher__item">EN</a>
                            <a href="#" className="langSwitcher__item">FR</a>
                        </div>

                    </div>

                </header>

                <div className="contentContainer contentContainer--viewport">

                    <div className="offerInfofr">

                        <img src={jacket} />

                    </div>

                    <div className="productBlock">


                        <div className="pinValidation pinValidation--fr">

                            <h3 className="pinValidation__header textColorDarkBlue">ENTREZ VOTRE NIP</h3>
                            <h3 className="pinValidation__caption textColorDarkBlue">QUI SE TROUVE DANS DES CAISSES DE MOLSON ULTRA POUR COURIR LA CHANCE DE GAGNER</h3>

                            <form onSubmit={this.handleSubmit} className="form form--pinValidation">

                                <p className="field required form--pinValidation__pin">
                                    <input required type='text' size="6" maxLength="6" placeholder='ENTREZ VOTRE NIP' value={this.state.value} onChange={this.handleChange}></input>
                                </p>

                                <p className="field form--pinValidation__btn">
                                    <button className="btn hvr-push" value='submit'>Soumettre</button>
                                </p>

                            </form>



                        </div>

                    </div>

                </div>

                <footer className="footer backgroundColorRed">

                    <div className="footerContents">

                        <ul className="footerSocials">

                            <li className="footerSocials__item">
                                <a href="https://www.facebook.com/MolsonUltra" target="_blank">
                                    <img src={facebook} />
                                </a>
                            </li>

                            <li className="footerSocials__item">
                                <a href="https://twitter.com/molsonultra" target="_blank">
                                    <img src={twitter} />
                                </a>
                            </li>

                            <li className="footerSocials__item">
                                <a href="https://www.instagram.com/molsonultra/?hl=en" target="_blank">
                                    <img src={instagram} />
                                </a>
                            </li>

                        </ul>

                        <ul className="footerLinks">

                            <li className="footerLinks__item">
                                <a href="/TermsPagefr">Modalités</a>
                            </li>

                            <li className="footerLinks__item">
                                <a href="/ContactPagefr">Nous joindre</a>
                            </li>

                        </ul>

                    </div>

                </footer>

            </div>

        )

    };
}
