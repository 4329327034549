import React, { Component } from "react";
import * as cookies from "../cookie.js";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import logo from './assets/svg/ult-secondary-variant-lock-up-fr.svg';
import gillnhl from './assets/img/homegym.png';
import jacket from './assets/img/Header.png';
import facebook from './assets/svg/facebook.svg';
import twitter from './assets/svg/twitter.svg';
import instagram from './assets/svg/instagram.svg';
import { Link } from "react-router-dom";

export default class ConfirmPage extends Component {
    constructor(props) {
        super(props);

        require("./app.css");

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleConfirm = this.handleConfirm.bind(this);
        this.state = {
            isLoading: true,
            dataSource: {},
            value: '' };

    }

    componentDidMount() {

        cookies.checkCookie();

    }

    handleChange(event) {
        this.setState({ value: event.target.value });
    }
    handleSubmit(event) {
       // alert((this.state.value.toUpperCase()));
        window.location = './UserFormfr/' + this.props.location.data.userpin;

        event.preventDefault();
    }

    async handleConfirm(event) {
        event.preventDefault();
        // alert((this.state.value.toUpperCase()));
        try {
            const response = await fetch('https://9rqbkg15lf.execute-api.us-east-1.amazonaws.com/test', {
                                method: 'POST',
                                headers: {
                                    'Accept': 'application/json',
                                    'Content-Type': 'application/json'
                                },
                              body: JSON.stringify(this.props.location.data, null, 2)
                            })
            let responseJson = await response.json();
            this.setState(
                {
                    isLoading: false,
                    dataSource: responseJson
                },
                function () {


                }
            );
        } catch (error) {
            console.error(error);
        }



        let { dataSource } = this.state;

        if (this.state.isLoading) { }

        else {
            if (dataSource == "Invalid") {
                window.location = '/InvalidPinPagefr';
            }
            else {
                    if (dataSource == "TooMany") {
                        alert('Désolé, ce concours est limité à une participation par personne chaque jour.');
                    }
                    else {
                        this.props.history.push({ pathname: '/ContestEntryfr', data: dataSource });
                    }
                 }
        }



    }
    render() {
        const { data } = this.props.location
       console.log(data);
        if (data === undefined) window.location= '/PinPagefr';
        return (

          <div className="app app--shippingConfirm">

            <header className="navigationMenu backgroundColorWhite">

              <div className="navigationMenuContents">

                <a href="/" className="navigationMenu__logo"><img src={logo} alt="logo"/></a>

                <div className="langSwitcher langSwitcher--fr">
                  <a href="#" className="langSwitcher__item">EN</a>
                  <a href="#" className="langSwitcher__item">FR</a>
                </div>

              </div>

            </header>

            <div className="shaderWrap shaderWrap--shippingConfirm">

                    <div className="contentContainer contentContainer--viewport contentContainer--shippingConfirm">

                        <div className="offerInfo">

                            <img src={jacket} />

                        </div>

                        <div className="productBlock">


                            <div className="pinValidation pinValidation--fr">

                                <h3 className="pinValidation__header textColorDarkBlue">ENTREZ VOTRE NIP</h3>
                                <h3 className="pinValidation__caption textColorDarkBlue">QUI SE TROUVE DANS DES CAISSES DE MOLSON ULTRA POUR COURIR LA CHANCE DE GAGNER</h3>

                                <form onSubmit={this.handleSubmit} className="form form--pinValidation">

                                    <p className="field required form--pinValidation__pin">
                                        <input required type='text' size="6" maxLength="6" placeholder='ENTREZ VOTRE NIP' value={this.state.value} onChange={this.handleChange}></input>
                                    </p>

                                    <p className="field form--pinValidation__btn">
                                        <button className="btn hvr-push" value='submit'>Soumettre</button>
                                    </p>

                                </form>



                            </div>

                        </div>

                    </div>

                    <div className="modal modal--shippingConfirm">

                        <div className="modalTitle backgroundColorBlue">
                            <h3 className="modalTitle__title">CONFIRMATION</h3>
                        </div>

                        <div className="modalContents">

                            <div className="modalCustomerInfo">
                                <span className="modalCustomerInfo__name">{data.firstname} {data.lastname}</span>
                                <span className="modalCustomerInfo__address">{data.address1}</span>
                                <span className="modalCustomerInfo__location">{data.city}, {data.province}</span>
                                <span className="modalCustomerInfo__postal">{data.postalcode}</span>
                                <span className="modalCustomerInfo__email">{data.email}</span>
                            </div>




                            <div className="jacketSelectionHeading">
                                <h1 className="jacketSelectionHeading__header textColorBlue">Molson Ultra</h1>


                                <span>       <img src={gillnhl} className="jacketSelectionHeading__logo" alt="logo" /></span>
                                <h1 className="jacketSelectionHeading__header textColorBlue">gym à domicile</h1>
                            </div>





                            <form name='SkillTestForm' onSubmit={this.handleConfirm} className="form">
                                <a href={"/UserFormfr/" + this.props.location.data.userpin} className="btn btn--grey hvr-push modalContents__edit">Modifier</a>
                                <p className="field form--skillTest__btn">
                                    <button className="btn hvr-push">Soumettre</button>
                                </p>
                            </form>

                        </div>

                    </div>

                </div>

            <footer className="footer backgroundColorRed">

              <div className="footerContents">

                <ul className="footerSocials">

                  <li className="footerSocials__item">
                    <a href="https://www.facebook.com/MolsonUltra" target="_blank">
                      <img src={facebook}/>
                    </a>
                  </li>

                  <li className="footerSocials__item">
                    <a href="https://twitter.com/molsonultra" target="_blank">
                      <img src={twitter}/>
                    </a>
                  </li>

                  <li className="footerSocials__item">
                    <a href="https://www.instagram.com/molsonultra/?hl=en" target="_blank">
                      <img src={instagram}/>
                    </a>
                  </li>

                </ul>

                <ul className="footerLinks">

                  <li className="footerLinks__item">
                    <a href="/TermsPagefr">Modalités</a>
                  </li>


                  <li className="footerLinks__item">
                    <a href="/ContactPagefr">Nous Joindre</a>
                  </li>

                </ul>

              </div>

            </footer>

          </div>

        )

    };
}
