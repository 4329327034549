import React, { Component } from "react";
import * as cookies from "../cookie.js";

import logo from './assets/svg/ult-secondary-variant-lock-up-fr.svg';
import logoAlt from './assets/svg/ult-secondary-variant-lock-up-fr_large.svg';
import gillnhl from './assets/img/homegym.png';

import facebook from './assets/svg/facebook.svg';
import twitter from './assets/svg/twitter.svg';
import instagram from './assets/svg/instagram.svg';
import { Link } from "react-router-dom";
export default class ConfirmationCompletePage extends Component {
    constructor(props) {
        super(props);

        require("./app.css");



    }

    componentDidMount() {

        cookies.checkCookie();

    }


    render() {
        if (this.props.location.data === undefined) window.location = '/PinPage';
        let conf = this.props.location.data.Item.date;
      // let conf = this.date;
        return (

          <div className="app app--confirmationComplete">

            <header className="navigationMenu backgroundColorWhite">

              <div className="navigationMenuContents">

                <a href="/" className="navigationMenu__logo"><img src={logo} alt="logo"/></a>

                <div className="langSwitcher langSwitcher--fr">
                  <a href="ContestEntry" className="langSwitcher__item">EN</a>
                  <a href="#" className="langSwitcher__item">FR</a>
                </div>

              </div>

            </header>

            <div className="contentContainer contentContainer--viewport contentContainer--confirmationComplete">

              <div className="confirmationDetails">

                <div className="productSelectionImage">
                  <img className="productSelectionImage__img" src={gillnhl}/>
                </div>

                <h1 className="confirmationDetails__confirmationNumber textColorBlue">Confirmation: {conf}</h1>
                <span className="confirmationDetails__notice textColorGrey">Merci de vous être inscrit au concours</span>

                <div className="jacketSelectionHeading">
                            <h1 className="jacketSelectionHeading__header textColorBlue">gym à domicile</h1>

                  <h1 className="jacketSelectionHeading__header textColorBlue">Molson Ultra</h1>
                </div>

                <span className="confirmationDetails__notice textColorGrey">Vous recevrez sous peu un courriel<br></br> indiquant votre numéro de confirmation.</span>
                <p className="confirmationDetails__text textColorGrey">Vous faites maintenant partie d’un groupe de candidats parmi lesquels nous choisirons des gagnants au hasard après que le concours se terminera le 30 mars.</p>
                <p className="confirmationDetails__text textColorGrey">Si vous êtes choisi comme gagnant, vous recevrez un courriel de félicitations avec une confirmation d’expédition.</p>
                <p className="confirmationDetails__text textColorGrey">Bonne chance!</p>
                
                <img src={logoAlt} className="confirmationDetails__logo" alt="logo"/>


              </div>

            </div>

            <footer className="footer backgroundColorRed">

              <div className="footerContents">

                <ul className="footerSocials">
                            <li className="footerSocials__item">
                                <a href="https://www.facebook.com/MolsonUltra" target="_blank">
                                    <img src={facebook} />
                                </a>
                            </li>

                            <li className="footerSocials__item">
                                <a href="https://twitter.com/molsonultra" target="_blank">
                                    <img src={twitter} />
                                </a>
                            </li>

                            <li className="footerSocials__item">
                                <a href="https://www.instagram.com/molsonultra/?hl=en" target="_blank">
                                    <img src={instagram} />
                                </a>
                            </li>

                </ul>

                <ul className="footerLinks">

                  <li className="footerLinks__item">
                    <a href="/TermsPagefr">Modalités</a>
                  </li>

                  <li className="footerLinks__item">
                    <a href="/ContactPagefr">Nous Joindre</a>
                  </li>

                </ul>

              </div>

            </footer>

          </div>

        )

    };
}
