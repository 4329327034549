import React, { Component } from "react";
import * as cookies from "../cookie.js";
import logo from './assets/img/ReliefCatcherBlueBg_Update.png';
import logo2 from './assets/svg/ML_PRIMARY_VERT_2C_DBG.svg';
import MLB from './assets/img/MLB.png';
import Rawlings from './assets/img/Rawlings.png';
import mitt from './assets/img/ML_Relief_Catcher.png';
import GoldText from './assets/img/GoldText.png';
import celebrate from './assets/img/Celebrate_Responsibly.png';
import facebook from './assets/svg/facebook.svg';
import twitter from './assets/svg/twitter.svg';
import instagram from './assets/svg/instagram.svg';
import videofile from './assets/videos/video16x9b.mp4';
import videoposter from './assets/img/video.PNG';
import { Link } from "react-router-dom";
export default class PinPage extends Component {
    constructor(props) {
        super(props);

        require("./app.css");

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = { value: '' };

    }

    componentDidMount() {

        cookies.checkCookie();

    }

    handleChange(event) {
        this.setState({ value: event.target.value });
    }
    handleSubmit(event) {
       // alert((this.state.value.toUpperCase()));
        window.location = './UserForm/' + this.state.value.toUpperCase();

        event.preventDefault();
    }

    render() {

        return (

          <div className="app app--enterPin">



              <div className="navigationMenuContents">

                <a href="/" className="navigationMenu__logo"><img class="headerimage" src={logo} alt="logo"/></a>

                    <h1 className="pin__header"> THE FIRST GLOVE DESIGNED TO CATCH MILLER LITE.</h1>
                    <h1 className="pin__header"> BE SOCIAL. STAY DISTANT.</h1>
              </div>
               

            <div className="contentContainer contentContainer--viewport">
                <div className="contentContainerTop">
                        <div className="offerInfo">
                
                        <img className="glove" src={mitt} />
               
                     </div>
                    <div className="offerInfoRight">

                            <div className="textGold">ENTER FOR A</div>
                            <div className="textGold">CHANCE TO WIN*</div>
                            <div className="textWhite">1 OF 5 MILLER LITE</div>
                            <div className="textWhite">RELIEF CATCHERS!</div>
                            <form onSubmit={this.handleSubmit} className="form form--pinValidation">



                                <p className="field form--pinValidation__btn">
                                    <button className="btn hvr-push" value='submit'>ENTER NOW</button>
                                </p>

                            </form>
                            <div className="LogoAlign">
                                <div className="MLBpin"><img className="MLB_Img" src={MLB} /></div>
                                <div className="Rawlingspin"><img className="Rawlings_Img" src={Rawlings} /></div>
                            </div>
                    </div>
                    </div>
                    <div className="contentContainerBottom">
                        <div className="GoldText">

                            <img className="GoldTextImg" src={GoldText} />

                        </div>
                        <div className="Video">
                            <video width="100%" height="100%" poster={videoposter} controls>
                                <source src={videofile} type="video/mp4"></source>
                            </video>
                        </div>
                        </div>

            </div>

                <footer className="footer">
                    <div className="disclaimertext"> *Must be legal drinking age. No purchase necessary. Skill-Testing Question Applies. Five (5) Prizes (1 x Miller Lite custom catcher's glove) avail. to be won, each worth approx. $300.00 CAD. Contest ends 12:00pm (ET) on July 19, 2021 ("Entry Deadline"). Visit www.millerlite.ca/reliefcatcher for contest details</div><div className="disclaimertext">™/© 2021 MLB. </div>
                    <div className="footerContents">

                        <img src={celebrate} className="celebrate" alt="celebrate responsibly" />
                        <div className="footertext"> ® 2021 Molson Coors Brewing Company</div>

                        <ul className="footerLinks">

                            <li className="footerLinks__item">
                                <a href="/TermsPage">Terms & Conditions</a>
                            </li>
                            <li className="footerLinks__item">
                                <a href="/FAQ">FAQ</a>
                            </li>


                            <li className="footerLinks__item">
                                <a href="/ContactPage">Contact Us</a>
                            </li>

                        </ul>

                        <ul className="footerSocials">

                            <li className="footerSocials__item">
                                <a href="https://www.facebook.com/millerliteca/" target="_blank">
                                    <img src={facebook} />
                                </a>
                            </li>

                            <li className="footerSocials__item">
                                <a href="https://twitter.com/MillerLiteCA" target="_blank">
                                    <img src={twitter} />
                                </a>
                            </li>

                            <li className="footerSocials__item">
                                <a href="https://www.instagram.com/millerliteca/?hl=en" target="_blank">
                                    <img src={instagram} />
                                </a>
                            </li>

                        </ul>

                    </div>

                </footer>  

          </div>

        )

    };
}
