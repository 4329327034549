import React, { Component } from "react";
import * as cookies from "../cookie.js";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import logo from './assets/svg/ult-secondary-variant-lock-up-fr.svg';
import gillnhl from './assets/svg/GIII_NHL_Logo_FR.svg';

import facebook from './assets/svg/facebook.svg';
import twitter from './assets/svg/twitter.svg';
import instagram from './assets/svg/instagram.svg';
let Pin = '';
let teamname = '';
export default class UserForm extends Component {
    constructor(props) {
        super(props);
        require("./app.css");
    }

    componentDidMount() {

        Pin = this.props.match.params.Pin;
        if (Pin.length != 6) {
            window.location = '/PinPagefr';
        }
        cookies.checkCookie();

    }

    render() {
        
        return (

          <div className="app app--shippingForm">

            <header className="navigationMenu backgroundColorWhite">

              <div className="navigationMenuContents">

                <a href="/" className="navigationMenu__logo"><img src={logo} alt="logo"/></a>

                <div className="langSwitcher langSwitcher--fr">
                  <a href={"/UserForm/" + this.props.match.params.Pin} className="langSwitcher__item">EN</a>
                  <a href="#" className="langSwitcher__item">FR</a>
                </div>

              </div>

            </header>

            <div className="contentContainer contentContainer--viewport contentContainer--shippingForm">

              <Formik
                        initialValues={{ email: '', firstname: '', lastname: '', address1: '', postalcode: '', city: '', userpin: '', province: ''}}
                  validate={values => {
                      const errors = {};
                      if (!values.email) {
                          errors.email = 'Requis';
                      } else if (
                          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                      ) {
                          errors.email = 'Adresse Courriel non valide';
                      }
                      values.userpin = Pin;
                      if (!values.firstname) { errors.firstname = 'Requis'; }
                      if (!values.lastname) { errors.lastname = 'Requis'; }
                      if (!values.address1) { errors.address1 = 'Requis'; }
                      if (!values.postalcode) { errors.postalcode = 'Requis'; }
                      if (!values.city) { errors.city = 'Requis'; }
                      return errors;
                  }}
                  onSubmit={(values, { setSubmitting }) => {
  setSubmitting(false);
                          this.props.history.push({ pathname:'/ConfirmPagefr', data: values });


                  }}
              >
                  {({ isSubmitting }) => (

                          <Form className="form form--shippingForm ">

                            <h1 className="form--shippingForm__header">Adresse de Livraison</h1>

                            <p className="field required form--shippingForm__firstName">
                              <ErrorMessage name="firstname" component="div" className="fieldError"/>
                              <Field type="text" name="firstname" placeholder="Prénom"/>
                            </p>

                            <p className="field required form--shippingForm__lastName">
                              <ErrorMessage name="lastname" component="div" className="fieldError"/>
                              <Field type="text" name="lastname" placeholder="Nom de Famille"/>
                            </p>

                            <span className="form--shippingForm__poNotice">Note: Boîtes postales non acceptées.</span>

                            <p className="field required form--shippingForm__address">
                              <ErrorMessage name="address1" component="div" className="fieldError"/>
                              <Field type="text" name="address1" placeholder="Addresse"/>
                            </p>

                            <p className="field required form--shippingForm__city">
                              <ErrorMessage name="city" component="div" className="fieldError"/>
                              <Field type="text" name="city" placeholder="Ville"/>
                            </p>

                            <p className="field required form--shippingForm__province">
                              <ErrorMessage name="province" component="div" className="fieldError"/>
                                <Field as="select" placeholder='province' name='province' required>
                                <option value="" selected disabled hidden>Province</option>
                                <option value="AB">Alberta</option>
                                <option value="BC">Colombie-Britannique</option>
                                <option value="MB">Manitoba</option>
                                <option value="NB">Nouveau-Brunswick</option>
                                <option value="NS">Nouvelle-Écosse</option>
                                <option value="ON">Ontario</option>
                                <option value="PE">Île-du-Prince-Édouard</option>
                                <option value="QC">Québec</option>
                                <option value="SK">Saskatchewan</option>
                              </Field>
                            </p>

                            <p className="field required form--shippingForm__postal">
                              <ErrorMessage name="postalcode" component="div" className="fieldError"/>
                              <Field type="text" name="postalcode" placeholder="Code Postale"/>
                            </p>

                            <h2 className="form--shippingForm__header form--shippingForm__header--contact">Contact</h2>

                            <span className="form--shippingForm__emailNotice">Pour confirmation d'expédition seulement</span>

                            <p className="field required form--shippingForm__email">
                                <ErrorMessage name="email" component="div" className="fieldError"/>
                                <Field type="email" name="email" placeholder="Adresse de Courriel"/>
                            </p>

                            <p className="field form--shippingForm__btn">
                              <button className="btn hvr-push" type="submit" disabled={isSubmitting}>Soumettre</button>
                            </p>

                          </Form>
                  )}
              </Formik>

            </div>

            <footer className="footer backgroundColorRed">

              <div className="footerContents">

                <ul className="footerSocials">

                  <li className="footerSocials__item">
                    <a href="https://www.facebook.com/MolsonUltra" target="_blank">
                      <img src={facebook}/>
                    </a>
                  </li>

                  <li className="footerSocials__item">
                    <a href="https://twitter.com/molsonultra" target="_blank">
                      <img src={twitter}/>
                    </a>
                  </li>

                  <li className="footerSocials__item">
                    <a href="https://www.instagram.com/molsonultra/?hl=en" target="_blank">
                      <img src={instagram}/>
                    </a>
                  </li>

                </ul>

                <ul className="footerLinks">

                  <li className="footerLinks__item">
                    <a href="/TermsPagefr">Modalités</a>
                  </li>

                  <li className="footerLinks__item">
                    <a href="/ContactPagefr">Nous Joindre</a>
                  </li>

                </ul>

              </div>

            </footer>

          </div>

        )

    };
}
