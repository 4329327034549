import React, { Component } from 'react';


import { BrowserRouter as Router, Route, Switch, Link, Redirect } from 'react-router-dom';

import MolsonIndexfr from "./pages/indexfr.jsx";

import MainPage from "./pages";
import ConfirmPage from "./pages/confirm.jsx";
import PinPage from "./pages/pin.jsx";
import InvalidPinPage from "./pages/invalidpin.jsx";
import SkillPage from "./pages/skilltest.jsx";
import UserForm from "./pages/userform.jsx";
import NotFoundPage from "./pages/404";
import TermsPage from "./pages/terms.jsx";
import FAQPage from "./pages/faq.jsx";
import ContactPage from "./pages/contact.jsx";
import ContestEntryPage from "./pages/contestentry.jsx";

import MainPagefr from "./pages/indexfr.jsx";
import ConfirmPagefr from "./pages/confirmfr.jsx";
import PinPagefr from "./pages/pinfr.jsx";
import InvalidPinPagefr from "./pages/invalidpinfr.jsx";
import SkillPagefr from "./pages/skilltestfr.jsx";
import UserFormfr from "./pages/userformfr.jsx";
import TermsPagefr from "./pages/termsfr.jsx";
import FAQPagefr from "./pages/faqfr.jsx";
import ContactPagefr from "./pages/contactfr.jsx";
import ContestEntryPagefr from "./pages/contestentryfr.jsx";


class App extends Component{
    render(){
        return <Router>
                  <Switch>
                <Route exact path="/" component={MainPage} />


         
                    <Route exact path="/TermsPage" component={TermsPage} />
                    <Route exact path="/FAQ" component={FAQPage} />
                    <Route exact path="/ContactPage" component={ContactPage} />
           

              

                    <Route exact path="/404" component={NotFoundPage} />
                    <Redirect to="/404" />
                  </Switch>
            </Router>

    }
}

export default App;
