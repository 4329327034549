import React, { Component } from "react";
import * as cookies from "../cookie.js";
import logo from './assets/img/ReliefCatcherBlueBg_Update.png';
import logo2 from './assets/svg/ML_PRIMARY_VERT_2C_DBG.svg';
import MLB from './assets/img/MLB.png';
import Rawlings from './assets/img/Rawlings.png';
import mitt from './assets/img/ML_Relief_Catcher.png';
import celebrate from './assets/img/Celebrate_Responsibly.png';
import facebook from './assets/svg/facebook.svg';
import twitter from './assets/svg/twitter.svg';
import instagram from './assets/svg/instagram.svg'
import { Link } from "react-router-dom";

export default class ConfirmationCompletePage extends Component {
    constructor(props) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);
        require("./app.css");
        this.state = {
            isLoading: true,
            dataSource: {},
            value: ''
        };


    }

    componentDidMount() {

        cookies.checkCookie();

    }
    async handleSubmit(event) {
        event.preventDefault();
        // alert('A name was submitted: ' + this.state.value);

        //alert(JSON.stringify(this.props.location.data.Item, null, 2));
            try {
                const response = await fetch('https://up2wogskid.execute-api.us-east-1.amazonaws.com/contest', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(this.props.location.data.Item, null, 2)
                })
                let responseJson = await response.json();
                this.setState(
                    {
                        isLoading: false,
                        dataSource: responseJson
                    },
                    function () {


                    }
                );
            } catch (error) {
                console.error(error);
            }



            let { dataSource } = this.state;

            if (this.state.isLoading) { }

            else {
                if (dataSource == "Invalid") {
                    window.location = '/InvalidPinPage';
                }
                else {
                    if (dataSource == "TooMany") {
                        alert('Sorry this contest is limited to two entries per person.');
                    }
                    else {
                        this.props.history.push({ pathname: '/ContestEntry', data: dataSource });
                    }
                }
           }
        var win = window.open('https://www.instagram.com/millerliteca/?hl=en', '_blank');
        win.focus();
 
 
    }

    render() {
       if (this.props.location.data === undefined) window.location = '/PinPage';
        let conf = this.props.location.data.Item.date;
        this.props.location.data.Item.insta = true;
        
        ///let conf = this.date
        return (

            <div className="app app--enterPin">



                <div className="navigationMenuContents">

                    <a href="/" className="navigationMenu__logo"><img class="headerimage" src={logo} alt="logo" /></a>

                    <h1 className="pin__header"> THE FIRST GLOVE DESIGNED TO CATCH MILLER LITE.</h1>
                  
                </div>


                <div className="contentContainer contentContainer--viewport">
                    <div className="contentContainerTop">
                        <div className="ConfofferInfo">

                            <img className="confglove" src={mitt} />

                        </div>
                        <div className="ConfInfoRight">

                            <div className="textGoldConf">CONFIRMATION: {conf}</div>
                            <div className="textWhiteConf">Thank you for Entering into the Miller Lite Relief Catcher Contest. You will receive a email with your confirmation shortly.</div>
                            <div className="textWhiteConf">You are now entered into a pool of candidates where we will randomly select the winners after the contest closes on July 19 2021.</div>
                            <div className="textWhiteConf">If you have been selected as a Winner, you will receive a Congratulations email with prizing delivery details.</div>
                            <div className="textGoldConf">FOLLOW US ON INSTAGRAM AND WE'LL ENTER YOU A SECOND TIME TO DOUBLE YOUR CHANCES TO WIN!</div>
                            <form onSubmit={this.handleSubmit} className="form form--pinValidation">
                                <p className="field form--pinValidation__btn">
                                   <button className="btn hvr-push" value='submit'>FOLLOW</button>
                                </p>
                                <div className="textGoldConf">GOOD LUCK!</div>
                            </form>
               

                </div>
              </div>

            </div>

                <div className="FooterLogos">
                    <div className="MLB"><img className="MLB_Img" src={MLB} /></div>
                    <div className="Rawlings"><img className="Rawlings_Img" src={Rawlings} /></div>
                </div>
                <footer className="footer">
                    <div className="disclaimertext"> *Must be legal drinking age. No purchase necessary. Skill-Testing Question Applies. Five (5) Prizes (1 x Miller Lite custom catcher's glove) avail. to be won, each worth approx. $300.00 CAD. Contest ends 12:00pm (ET) on July 19, 2021 ("Entry Deadline"). Visit www.millerlite.ca/reliefcatcher for contest details</div><div className="disclaimertext">™/© 2021 MLB. </div>
                    <div className="footerContents">

                        <img src={celebrate} className="celebrate" alt="celebrate responsibly" />
                        <div className="footertext"> ® 2021 Molson Coors Brewing Company</div>

                        <ul className="footerLinks">

                            <li className="footerLinks__item">
                                <a href="/TermsPage">Terms & Conditions</a>
                            </li>
                            <li className="footerLinks__item">
                                <a href="/FAQ">FAQ</a>
                            </li>


                            <li className="footerLinks__item">
                                <a href="/ContactPage">Contact Us</a>
                            </li>

                        </ul>

                        <ul className="footerSocials">

                            <li className="footerSocials__item">
                                <a href="https://www.facebook.com/millerliteca/" target="_blank">
                                    <img src={facebook} />
                                </a>
                            </li>

                            <li className="footerSocials__item">
                                <a href="https://twitter.com/MillerLiteCA" target="_blank">
                                    <img src={twitter} />
                                </a>
                            </li>

                            <li className="footerSocials__item">
                                <a href="https://www.instagram.com/millerliteca/?hl=en" target="_blank">
                                    <img src={instagram} />
                                </a>
                            </li>

                        </ul>

                    </div>

                </footer>  

          </div>

        )

    };
}
