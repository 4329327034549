import React, { Component } from "react";
import * as cookies from "../cookie.js";

import logo from './assets/svg/mol_ret_cad_molson_primary_lockup_1C_rgb_V2.svg';
import facebook from './facebook.svg';
import twitter from './twitter.svg';
import instagram from './instagram.svg';
import { Link } from "react-router-dom";
export default class FAQPage extends Component {
    constructor(props) {
        super(props);

        require("./app.css");


    }

    componentDidMount() {

        cookies.checkCookie();

    }


    render() {

        return (

          <div className="app app--faq">

            <header className="navigationMenu backgroundColorRed">

              <div className="navigationMenuContents">

                <a href="/" className="navigationMenu__logo"><img src={logo} alt="logo"/></a>

                <div className="langSwitcher langSwitcher--fr langSwitcher--molsonExport">
                  <a href="#" className="langSwitcher__item">EN</a>
                  <a href="#" className="langSwitcher__item">FR</a>
                </div>

              </div>

            </header>

            <div className="contentContainer contentContainer--viewport">

              <div className="faq">

                <h1 className="faq__title textColorBlue">Freqeuntly Asked Questions</h1>

                <div className="faqEntry textColorDarkBlue">
                  <h3 className="faqEntry__question">My question?</h3>
                  <p className="faqEntry__answer">Ipsum lorem.</p>
                </div>

                <div className="faqEntry textColorDarkBlue">
                  <h3 className="faqEntry__question">My question?</h3>
                  <p className="faqEntry__answer">Ipsum lorem.</p>
                </div>

                <div className="faqEntry textColorDarkBlue">
                  <h3 className="faqEntry__question">My question?</h3>
                  <p className="faqEntry__answer">Ipsum lorem.</p>
                </div>

              </div>

            </div>

            <footer className="footer backgroundColorRed">

              <div className="footerContents">

                <ul className="footerSocials">

                  <li className="footerSocials__item">
                    <a href="https://www.facebook.com/MolsonCanadian" target="_blank">
                      <img src={facebook}/>
                    </a>
                  </li>

                  <li className="footerSocials__item">
                    <a href="https://twitter.com/Molson_Canadian" target="_blank">
                      <img src={twitter}/>
                    </a>
                  </li>

                  <li className="footerSocials__item">
                    <a href="https://www.instagram.com/molsoncanadian/" target="_blank">
                      <img src={instagram}/>
                    </a>
                  </li>

                </ul>

                <ul className="footerLinks">

                  <li className="footerLinks__item">
                    <a href="/TermsPagefr">Modalit�s</a>
                  </li>

                  <li className="footerLinks__item">
                    <a href="/FAQPagefr">FAQ</a>
                  </li>

                  <li className="footerLinks__item">
                    <a href="/ContactPagefr">Nous Joindre</a>
                  </li>

                </ul>

              </div>

            </footer>

          </div>

        )

    };
}
