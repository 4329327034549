import React, { Component, useState } from 'react';
import { render } from 'react-dom';
import logo from './assets/svg/ult-secondary-variant-lock-up-fr.svg';
import gillnhl from './assets/svg/GIII_NHL_Logo_EN.svg';
import jacket from './assets/img/Header.png';
import facebook from './assets/svg/facebook.svg';
import twitter from './assets/svg/twitter.svg';
import instagram from './assets/svg/instagram.svg';
import * as cookies from "../cookie.js";
let Pin = '';


export default class SkillPage extends Component  {
    constructor(props) {
        super(props);

        require("./app.css");

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = {
            isLoading: true,
            dataSource: {},
            value: ''
        };

    }

    async componentDidMount() {

        cookies.checkCookie();

        Pin = this.props.match.params.Pin;
        if (Pin.length != 6) {
            window.location = '/InvalidPinPagefr';
        }

        try {
            const response = await fetch('https://9rqbkg15lf.execute-api.us-east-1.amazonaws.com/test?pin=' + Pin);
            let responseJson = await response.json();
            this.setState(
                {
                    isLoading: false,
                    dataSource: responseJson
                },
                function () {


                }
            );
        } catch (error) {
            console.error(error);
        }
        let { dataSource } = this.state;
        if (this.state.isLoading) { }
        else {
           if (dataSource != 1) { //Live
       //     if (dataSource != 1 && Pin != "AAAAAA") { //For Testing
                window.location = '/InvalidPinPagefr';
            }

        }

    }

    handleChange(event) {
        this.setState({ value: event.target.value });
    }
    handleSubmit(event) {
       // alert('A name was submitted: ' + this.state.value);
        if (this.state.value == 90) {
            window.location = '/UserFormfr/'+Pin;
        }
        else {
            alert("Désolé, ce n'est pas la bonne réponse.");

        }
        event.preventDefault();
    }

    render() {

        return (

          <div className="app app--skillTest">

            <header className="navigationMenu backgroundColorWhite">

                    <div className="navigationMenuContents">

                        <a href="/" className="navigationMenu__logo"><img class="headerimage" src={logo} alt="logo" /></a>

                        <div className="langSwitcher">
                            <a href="#" className="langSwitcher__item">EN</a>
                            <a href="PinPagefr" className="langSwitcher__item">FR</a>
                        </div>

                    </div>

            </header>

            <div className="shaderWrap">

                    <div className="contentContainer contentContainer--viewport">

                        <div className="offerInfo">

                            <img src={jacket} />

                        </div>

                        <div className="productBlock">


                            <div className="pinValidation pinValidation--fr">

                                <h3 className="pinValidation__header textColorDarkBlue">ENTREZ VOTRE NIP</h3>
                                <h3 className="pinValidation__caption textColorDarkBlue">QUI SE TROUVE DANS DES CAISSES DE MOLSON ULTRA POUR COURIR LA CHANCE DE GAGNER</h3>

                                <form onSubmit={this.handleSubmit} className="form form--pinValidation">

                                    <p className="field required form--pinValidation__pin">
                                        <input required type='text' size="6" maxLength="6" placeholder='ENTREZ VOTRE NIP' value={this.state.value} onChange={this.handleChange}></input>
                                    </p>

                                    <p className="field form--pinValidation__btn">
                                        <button className="btn hvr-push" value='submit'>Soumettre</button>
                                    </p>

                                </form>



                            </div>

                        </div>

                    </div>

              <div className="modal modal--skillTest">

                <div className="modalTitle backgroundColorBlue">
                  <h3 className="modalTitle__title">Félicitations!</h3>
                </div>

                <div className="modalContents">

                  <div className="eligibleNotice eligibleNotice--fr">
                                <h4 className="eligibleNotice__text">Vous êtes éligible pour gagner  un gym à domicile.</h4>

                  </div>

                  <span className="mathNotice">Question réglementaire d'arithmétique</span>

                  <form onSubmit={this.handleSubmit} className="form form--skillTest">

                    <p className="field form--skillTest__question textColorBlue">(12x9)-18=</p>

                    <p className="field required form--skillTest__answer">
                      <input className="text-input" type='number' placeholder='VOTRE RÉPONSE' value={this.state.value} onChange={this.handleChange}></input>
                    </p>

                    <p className="field form--skillTest__btn">
                      <button className="btn hvr-push" value='submit'>Soumettre</button>
                    </p>

                   

                  </form>

                </div>

              </div>

            </div>

            <footer className="footer backgroundColorRed">

              <div className="footerContents">

                <ul className="footerSocials">

                  <li className="footerSocials__item">
                    <a href="https://www.facebook.com/MolsonUltra" target="_blank">
                      <img src={facebook}/>
                    </a>
                  </li>

                  <li className="footerSocials__item">
                    <a href="https://twitter.com/molsonultra" target="_blank">
                      <img src={twitter}/>
                    </a>
                  </li>

                  <li className="footerSocials__item">
                    <a href="https://www.instagram.com/molsonultra/?hl=en" target="_blank">
                      <img src={instagram}/>
                    </a>
                  </li>

                </ul>

                <ul className="footerLinks">

                  <li className="footerLinks__item">
                    <a href="/TermsPagefr">Modalités</a>
                  </li>

                  <li className="footerLinks__item">
                    <a href="/ContactPagefr">Nous Joindre</a>
                  </li>

                </ul>

              </div>

            </footer>

          </div>

        )

    };
}
