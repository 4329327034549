import React from "react";

import logo from './assets/svg/ML_PRIMARY_VERT_2C_DBG.svg';
import facebook from './facebook.svg';
import twitter from './twitter.svg';
import instagram from './instagram.svg';
import { Link } from "react-router-dom";
import MLB from './assets/img/MLB.png';
import Rawlings from './assets/img/Rawlings.png';
import mitt from './assets/img/ML_Relief_Catcher.png';
import celebrate from './assets/img/Celebrate_Responsibly.png';

const NotFoundPage = () => {

    return (

      <div className="app app--404">


            <div className="navigationMenuContents">

                <a href="/"><img className="ML__logo" src={logo} alt="logo" /></a>

            </div>

        <div className="contentContainer contentContainer--viewport">

          <div className="contactInfo">

            <h1 className="contactInfo__title textWhite">404 Not Found</h1>

                    <p className="contactInfo__text textColorWhite">Sorry, the page you're looking for doesn't exist.</p>

                    <p className="contactInfo__text textColorWhite">Need help?</p>
                    <p className="contactInfo__text textColorWhite">Contact customer support at</p>
                    <p className="contactInfo__text textColorWhite">1-800-MOLSON1 or check out our <a href="/FAQ" className="textColorWhite">FAQ</a>.</p>

          </div>

        </div>


            <div className="FooterLogos">
                <div className="MLB"><img className="MLB_Img" src={MLB} /></div>
                <div className="Rawlings"><img className="Rawlings_Img" src={Rawlings} /></div>
            </div>
            <footer className="footer">
                <div className="disclaimertext"> *Must be legal drinking age. No purchase necessary. Skill-Testing Question Applies. Five (5) Prizes (1 x Miller Lite custom catcher's glove) avail. to be won, each worth approx. $300.00 CAD. Contest ends 12:00pm (ET) on July 19, 2021 ("Entry Deadline"). Visit www.millerlite.ca/reliefcatcher for contest details</div><div className="disclaimertext">�/� 2021 MLB. </div>
                <div className="footerContents">

                    <img src={celebrate} className="celebrate" alt="celebrate responsibly" />
                    <div className="footertext"> � 2021 Molson Coors Brewing Company</div>

                    <ul className="footerLinks">

                        <li className="footerLinks__item">
                            <a href="/TermsPage">Terms & Conditions</a>
                        </li>
                        <li className="footerLinks__item">
                            <a href="/FAQ">FAQ</a>
                        </li>


                        <li className="footerLinks__item">
                            <a href="/ContactPage">Contact Us</a>
                        </li>

                    </ul>

                    <ul className="footerSocials">

                        <li className="footerSocials__item">
                            <a href="https://www.facebook.com/millerliteca/" target="_blank">
                                <img src={facebook} />
                            </a>
                        </li>

                        <li className="footerSocials__item">
                            <a href="https://twitter.com/MillerLiteCA" target="_blank">
                                <img src={twitter} />
                            </a>
                        </li>

                        <li className="footerSocials__item">
                            <a href="https://www.instagram.com/millerliteca/?hl=en" target="_blank">
                                <img src={instagram} />
                            </a>
                        </li>

                    </ul>

                </div>

            </footer>  

      </div>

    );
};

export default NotFoundPage;
