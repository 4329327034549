import React, { Component } from "react";
import * as cookies from "../cookie.js";

import logo from './assets/svg/ult-secondary-variant-lock-up-fr.svg';
import gillnhl from './assets/svg/GIII_NHL_Logo_FR.svg';

import facebook from './assets/svg/facebook.svg';
import twitter from './assets/svg/twitter.svg';
import instagram from './assets/svg/instagram.svg';
import { Link } from "react-router-dom";
export default class ContactPage extends Component {
    constructor(props) {
        super(props);

        require("./app.css");



    }

    componentDidMount() {

        cookies.checkCookie();

    }


    render() {

        return (

          <div className="app app--contact">

            <header className="navigationMenu backgroundColorWhite">

              <div className="navigationMenuContents">

                <a href="/" className="navigationMenu__logo"><img src={logo} alt="logo"/></a>

                <div className="langSwitcher langSwitcher--fr">
                  <a href="./ContactPage" className="langSwitcher__item">EN</a>
                  <a href="#" className="langSwitcher__item">FR</a>
                </div>

              </div>

            </header>

            <div className="contentContainer contentContainer--viewport">

              <div className="contactInfo">

                <h1 className="contactInfo__title textColorBlue">Contact</h1>
                <p className="contactInfo__text textColorGrey">Besoin d'aide?</p>
                <p className="contactInfo__text textColorGrey">Contactez le service client au</p>
                <p className="contactInfo__text textColorGrey">1-800-MOLSON1</p>

              </div>

            </div>

            <footer className="footer backgroundColorRed">

              <div className="footerContents">

                <ul className="footerSocials">

                  <li className="footerSocials__item">
                    <a href="https://www.facebook.com/MolsonUltra" target="_blank">
                      <img src={facebook}/>
                    </a>
                  </li>

                  <li className="footerSocials__item">
                    <a href="https://twitter.com/molsonultra" target="_blank">
                      <img src={twitter}/>
                    </a>
                  </li>

                  <li className="footerSocials__item">
                    <a href="https://www.instagram.com/molsonultra/?hl=en" target="_blank">
                      <img src={instagram}/>
                    </a>
                  </li>

                </ul>

                <ul className="footerLinks">

                  <li className="footerLinks__item">
                    <a href="/TermsPagefr">Modalités</a>
                  </li>

                  <li className="footerLinks__item">
                    <a href="/ContactPagefr">Nous Joindre</a>
                  </li>

                </ul>

              </div>

            </footer>

          </div>

        )

    };
}
