import React, { Component } from "react";
import * as cookies from "../cookie.js";

import logo from './assets/svg/ML_PRIMARY_VERT_2C_DBG.svg';
import facebook from './facebook.svg';
import twitter from './twitter.svg';
import instagram from './instagram.svg';
import { Link } from "react-router-dom";
import MLB from './assets/img/MLB.png';
import Rawlings from './assets/img/Rawlings.png';
import mitt from './assets/img/ML_Relief_Catcher.png';
import celebrate from './assets/img/Celebrate_Responsibly.png';
export default class FAQPage extends Component {
    constructor(props) {
        super(props);

        require("./app.css");


    }

    componentDidMount() {

        cookies.checkCookie();

    }


    render() {

        return (

          <div className="app app--faq">

                <div className="navigationMenuContents">

                    <a href="/"><img className="ML__logo" src={logo} alt="logo" /></a>

                </div>

            <div className="contentContainer contentContainer--viewport">

              <div className="faq">

                <h1 className="faq__title textWhite">Freqeuntly Asked Questions</h1>

                <div className="faqEntry textWhiteConf">
                            <h3 className="faqEntry__question">How can I win a Miller Lite Relief Catcher ?</h3>
                            <p className="faqEntry__answer">To enter, visit www.millerlite.ca/ReliefCatcher.com (the “Contest Website”) and follow the on-screen instructions to complete the online entry form in full and submit the entry. All fields on the entry form must be completed unless they are otherwise indicated as optional. Limit: one (1) entry per person/email address. The Releasees (as defined below) will not be responsible for illegible, incomplete, lost, misdirected, technical failures or late entries, all of which will be void. For greater certainty and the avoidance of any doubt, you can use only one (1) email address to enter this Contest. If it is discovered by the Sponsor that any person has attempted to: (i) obtain more than the maximum stated number of entries as outlined in these Official Contest Rules; and/or (ii) use (or attempt to use) multiple names, identities and/or more than one (1) email address to enter the Contest; then he/she may be disqualified from the Contest and all of his/her entries voided. ADDITIONAL BONUS ENTRY: You can receive one (1) additional bonus entry by following @millerliteca on Instagram when prompted through the Contest Website. Your entry will be rejected if the entry form is not fully completed with all required information and submitted and received by the Entry Deadline.</p>
                </div>
                <div className="faqEntry textWhiteConf">
                    <h3 className="faqEntry__question">What address can we use for shipping?  </h3>
                    <p className="faqEntry__answer">The item can only be shipped to a Canadian mailing address (no postal boxes). </p>
                </div>
                <div className="faqEntry textWhiteConf">
                            <h3 className="faqEntry__question">Where do I call if I’m having problems entering?</h3>
                            <p className="faqEntry__answer">We’re sorry to hear you’re having difficulty entering the contest. Please ensure you are completing all mandatory fields. If you still are experiencing issues, please contact customer service at 1-800-Molson1. </p>
                </div>
                <div className="faqEntry textWhiteConf">
                            <h3 className="faqEntry__question">If I am a winner, how long does it take to receive my Miller Lite Miller Lite Branded Cooler Bag in the mail?</h3>
                            <p className="faqEntry__answer">Please allow a 3 to 4 weeks for delivery. </p>
                </div>
              
                    <div className="faqEntry textWhiteConf">
                        <h3 className="faqEntry__question">Who do I call if I haven’t received my Miller Lite Relief Catcher  and it’s past the estimated delivery timing or the item is damaged.</h3>
                        <p className="faqEntry__answer">Please contact 1-800-Molson1 and provide customer service with your order confirmation # that you received via e-mail.</p>
                    </div>
                    <div className="faqEntry textWhiteConf">
                        <h3 className="faqEntry__question">Can I Cancel my order or return the item?</h3>
                        <p className="faqEntry__answer">Unfortunately, we cannot cancel your order once you have placed it, nor can the free item be returned. </p>
                    </div>
                    <div className="faqEntry textWhiteConf">
                        <h3 className="faqEntry__question">Will Miller Lite be sending me communication via email for future promotions? </h3>
                        <p className="faqEntry__answer">No, your email information will only be used to fulfill this order unless you have opted into receiving future communications from MolsonCoors. </p>
                    </div>
                </div>
            </div>

                <div className="FooterLogos">
                    <div className="MLB"><img className="MLB_Img" src={MLB} /></div>
                    <div className="Rawlings"><img className="Rawlings_Img" src={Rawlings} /></div>
                </div>
                <footer className="footer">
                    <div className="disclaimertext"> *Must be legal drinking age. No purchase necessary. Skill-Testing Question Applies. Five (5) Prizes (1 x Miller Lite custom catcher's glove) avail. to be won, each worth approx. $300.00 CAD. Contest ends 12:00pm (ET) on July 19, 2021 ("Entry Deadline"). Visit www.millerlite.ca/reliefcatcher for contest details</div><div className="disclaimertext">™/© 2021 MLB. </div>
                    <div className="footerContents">

                        <img src={celebrate} className="celebrate" alt="celebrate responsibly" />
                        <div className="footertext"> ® 2021 Molson Coors Brewing Company</div>

                        <ul className="footerLinks">

                            <li className="footerLinks__item">
                                <a href="/TermsPage">Terms & Conditions</a>
                            </li>
                            <li className="footerLinks__item">
                                <a href="/FAQ">FAQ</a>
                            </li>


                            <li className="footerLinks__item">
                                <a href="/ContactPage">Contact Us</a>
                            </li>

                        </ul>

                        <ul className="footerSocials">

                            <li className="footerSocials__item">
                                <a href="https://www.facebook.com/millerliteca/" target="_blank">
                                    <img src={facebook} />
                                </a>
                            </li>

                            <li className="footerSocials__item">
                                <a href="https://twitter.com/MillerLiteCA" target="_blank">
                                    <img src={twitter} />
                                </a>
                            </li>

                            <li className="footerSocials__item">
                                <a href="https://www.instagram.com/millerliteca/?hl=en" target="_blank">
                                    <img src={instagram} />
                                </a>
                            </li>

                        </ul>

                    </div>

                </footer>  

          </div>

        )

    };
}
