import React, { Component } from "react";
import './app.css';
import logo from './assets/img/ReliefCatcherBlueBg_Update.png';
import logo2 from './assets/svg/ML_PRIMARY_VERT_2C_DBG.svg';
import logo3 from './assets/img/ML_IMT_Eng_Horiz_GOLD_MILLER_1C_4C.png';
import celebrate from './assets/img/Celebrate_Responsibly.png';
import facebook from './assets/svg/facebook.svg';
import twitter from './assets/svg/twitter.svg';
import instagram from './assets/svg/instagram.svg'
import MLB from './assets/img/MLB.png';
import Rawlings from './assets/img/Rawlings.png';
export default class MainPage extends Component {
    constructor(props) {
        super(props);

        require("./app.css");

        this.handleChange = this.handleChange.bind(this);

        this.state = {
            isLoading: true,
            dataSource: {},
            value: ''
        };

    }
    componentDidMount()
    {
    }
    handleChange(event) {
        this.setState({ value: event.target.value });
        this.ageoptions = {
            form: document.querySelector('form'),
            countries: false,
            expiry: 3600,
            age: this.state.value
        }
    }
    render() {
        return (
           
          <div className="app app--ageGate">

            <div className="contentContainer">

              <div className="langSwitcher langSwitcher--ageGate">
              
              </div>
                    <div className="logos">
                        <img src={logo2} className="navigationMenu__logo" alt="logo" />
                    </div>
                    <div className="logos">
                      
                        <img src={logo} className="navigationMenu__logo" alt="logo" />

                    </div>
                    <div className="ageGateContainer">
                        <h1 className="ageGateContainer__header"> </h1>
                        <h1 className="ageGateContainer__header"> </h1>
                <h1 className="ageGateContainer__header">THE CONTEST IS NOW CLOSED</h1>

                <div className="ageGateFields">


                <footer className="footer">
                               <div className="footerContents">

                                    <img src={celebrate} className="celebrate" alt="celebrate responsibly" />
                                    <div className="footertext"> ® 2021 Molson Coors Brewing Company</div>
                                     
                                    <ul className="footerLinks">

                                        <li className="footerLinks__item">
                                            <a href="/TermsPage">Terms & Conditions</a>
                                        </li>
                                        <li className="footerLinks__item">
                                            <a href="/FAQ">FAQ</a>
                                        </li>


                                        <li className="footerLinks__item">
                                            <a href="/ContactPage">Contact Us</a>
                                        </li>

                                    </ul>

                                     <ul className="footerSocials">

                                        <li className="footerSocials__item">
                                            <a href="https://www.facebook.com/millerliteca/" target="_blank">
                                                <img src={facebook} />
                                            </a>
                                        </li>

                                        <li className="footerSocials__item">
                                            <a href="https://twitter.com/MillerLiteCA" target="_blank">
                                                <img src={twitter} />
                                            </a>
                                        </li>

                                        <li className="footerSocials__item">
                                            <a href="https://www.instagram.com/millerliteca/?hl=en" target="_blank">
                                                <img src={instagram} />
                                            </a>
                                        </li>

                                    </ul>
                                   
                                </div>

                            </footer>  
                               
                           

                </div>

              </div>

            </div>

          </div>

            )

    };
}
