import React, { Component } from "react";
import * as cookies from "../cookie.js";

import invalidpin from './invalidpin.jpg';
import logo from './assets/svg/ult-secondary-variant-lock-up-fr.svg';
import gillnhl from './assets/svg/GIII_NHL_Logo_EN.svg';
import jacket from './assets/img/Header.png';
import facebook from './assets/svg/facebook.svg';
import twitter from './assets/svg/twitter.svg';
import instagram from './assets/svg/instagram.svg';
import { Link } from "react-router-dom";
export default class InvalidPinPage extends Component {
    constructor(props) {
        super(props);

        require("./app.css");

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = { value: '' };

    }

    componentDidMount() {

        cookies.checkCookie();

    }

    handleChange(event) {
        this.setState({ value: event.target.value });
    }
    handleSubmit(event) {
       // alert((this.state.value.toUpperCase()));
        window.location = './PinPagefr' + this.state.value.toUpperCase();

        event.preventDefault();
    }

    render() {

        return (

          <div className="app app--invalidPin">

                <header className="navigationMenu backgroundColorWhite">

                    <div className="navigationMenuContents">

                        <a href="/" className="navigationMenu__logo"><img class="headerimage" src={logo} alt="logo" /></a>

                        <div className="langSwitcher">
                            <a href="#" className="langSwitcher__item">EN</a>
                            <a href="PinPagefr" className="langSwitcher__item">FR</a>
                        </div>

                    </div>

                </header>

                <div className="shaderWrap">

                    <div className="contentContainer contentContainer--viewport">

                        <div className="offerInfo">

                            <img src={jacket} />

                        </div>

                        <div className="productBlock">


                            <div className="pinValidation pinValidation--fr">

                                <h3 className="pinValidation__header textColorDarkBlue">ENTREZ VOTRE NIP</h3>
                                <h3 className="pinValidation__caption textColorDarkBlue">QUI SE TROUVE DANS DES CAISSES DE MOLSON ULTRA POUR COURIR LA CHANCE DE GAGNER</h3>

                                <form onSubmit={this.handleSubmit} className="form form--pinValidation">

                                    <p className="field required form--pinValidation__pin">
                                        <input required type='text' size="6" maxLength="6" placeholder='ENTREZ VOTRE NIP' value={this.state.value} onChange={this.handleChange}></input>
                                    </p>

                                    <p className="field form--pinValidation__btn">
                                        <button className="btn hvr-push" value='submit'>Soumettre</button>
                                    </p>

                                </form>



                            </div>

                        </div>

                    </div>

              <div className="modal modal--skillTest">

                <div className="modalTitle backgroundColorBlue">
                  <h3 className="modalTitle__title">OUPS!</h3>
                </div>

                <div className="modalContents">

                  <h4 className="modalContents__invalidPin">Un NIP invalide a été utilisé.</h4>
                  <p className="modalContents__invalidPinInstructions">Ce NIP a déjà été utilisé ou a été entré incorrectement. Veuillez vous assurer que le NIP est entré exactement comme il apparaît sur la carte.</p>
                  <h5 className="modalContents__support">Problèmes?<br></br> Communiquez avec le service à la clientèle :<br></br>1-800-MOLSON1</h5>

                  <a href="/PinPagefr" className="btn hvr-push">Fermer</a>

                </div>

              </div>

            </div>

            <footer className="footer backgroundColorRed">

              <div className="footerContents">

                <ul className="footerSocials">

                  <li className="footerSocials__item">
                    <a href="https://www.facebook.com/MolsonUltra" target="_blank">
                      <img src={facebook}/>
                    </a>
                  </li>

                  <li className="footerSocials__item">
                    <a href="https://twitter.com/molsonultra" target="_blank">
                      <img src={twitter}/>
                    </a>
                  </li>

                  <li className="footerSocials__item">
                    <a href="https://www.instagram.com/molsonultra/?hl=en" target="_blank">
                      <img src={instagram}/>
                    </a>
                  </li>

                </ul>

                <ul className="footerLinks">

                  <li className="footerLinks__item">
                    <a href="/TermsPagefr">Modalités</a>
                  </li>

                  <li className="footerLinks__item">
                    <a href="/ContactPagefr">Nous joindre</a>
                  </li>

                </ul>

              </div>

            </footer>

          </div>

        )

    };
}
