import React, { Component } from "react";
import AgeGate from 'agegate';
import './app.css';
import logo from './assets/svg/ult-secondary-variant-lock-up-fr_large.svg';
export default class MainPage extends Component {
    constructor(props) {
        super(props);

        require("./app.css");

        this.handleChange = this.handleChange.bind(this);

        this.state = {
            isLoading: true,
            dataSource: {},
            value: ''
        };

    }
    componentDidMount()
    {
        let ageoptions = {
            form: document.querySelector('form'),
            countries: false,
            expiry: 3600,
            age: 19
        }

        if (true) {
            let gate = new AgeGate(ageoptions, (err) => {
                if (err) alert("Désolé, mais vous devez avoir l'âge légal pour participer.");
                else window.location = './PinPagefr';
            })
        }
    }
    handleChange(event) {
        this.setState({ value: event.target.value });
        this.ageoptions = {
            form: document.querySelector('form'),
            countries: false,
            expiry: 3600,
            age: this.state.value
        }
    }
    render() {
        return (

          <div className="app app--ageGate">

            <div className="contentContainer">

              <div className="langSwitcher langSwitcher--ageGate langSwitcher--fr">
                <a href="./" className="langSwitcher__item langSwitcher__item--ageGate">EN</a>
                <a href="#" className="langSwitcher__item langSwitcher__item--ageGate">FR</a>
              </div>

              <img src={logo} className="ageGateLogo" alt="logo" />

              <div className="ageGateContainer textColorBlue">

                <h1 className="ageGateContainer__header">Bienvenue</h1>
                <h2 className="ageGateContainer__caption">Entrez votre date de naissance</h2>

                <div className="ageGateFields">

                  <form name='agegate' className="form form--ageGate">

                                <p className="field required form--ageGate__province">
                                    <select className="text-input" placeholder='MM' name='Province' required value={this.state.value} onChange={this.handleChange}>
                        <option value="" selected disabled hidden>Province</option>
                                        <option value="AB">Alberta</option>
                                        <option value="BC">Colombie-Britannique</option>
                                        <option value="MB">Manitoba</option>
                                        <option value="NB">Nouveau-Brunswick</option>
                                        <option value="NS">Nouvelle-Écosse</option>
                                        <option value="ON">Ontario</option>
                                        <option value="PE">Île-du-Prince-Édouard</option>
                                        <option value="QC">Québec</option>
                                        <option value="SK">Saskatchewan</option>

                      </select>
                    </p>

                    <p className="field required form--ageGate__day">
                      <input className="text-input" type='number' placeholder='JJ' name='day' required min={1} max={31}></input>
                    </p>
                                <p className="field required form--ageGate__month">
                                    <input className="text-input" type='number' placeholder='MM' name='month' required min={1} max={12}></input>
                                </p>
                    <p className="field required form--ageGate__year">
                      <input className="text-input" type='number' placeholder='AAAA' name='year' required min={1905} max={2015}></input>
                    </p>

                    <p className="field form--ageGate__btn">
                      <button className="btn hvr-push" type='submit'>Soumettre</button>
                    </p>

                  </form>

                </div>

              </div>

            </div>

          </div>

            )

    };
}
