import React, { Component } from "react";
import * as cookies from "../cookie.js";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import logo from './assets/img/ReliefCatcherBlueBg_Update.png';
import logo2 from './assets/svg/ML_PRIMARY_VERT_2C_DBG.svg';
import MLB from './assets/img/MLB.png';
import Rawlings from './assets/img/Rawlings.png';
import mitt from './assets/img/ML_Relief_Catcher.png';
import celebrate from './assets/img/Celebrate_Responsibly.png';
import facebook from './assets/svg/facebook.svg';
import twitter from './assets/svg/twitter.svg';
import instagram from './assets/svg/instagram.svg'
import { Link } from "react-router-dom";
let Pin = '';
let teamname = '';


export default class UserForm extends Component {
    constructor(props) {
        super(props);
        require("./app.css");
  
    }

    componentDidMount() {

       
        cookies.checkCookie();
        //alert('A name was submitted: ' + this.props.history.location.selProduct);
    }



    render() {


        
        return (
           
            <div className="app app--shippingForm">
               
                <div className="navigationMenuContents">

                    <a href="/" className="navigationMenu__logo"><img class="headerimage" src={logo} alt="logo" /></a>


                </div>


                <div className="contentContainer contentContainer--viewport contentContainer--shippingForm">
                   
              <Formik
                        initialValues={{ email: '', firstname: '', lastname: '', address1: '', postalcode: '', city: '', userpin: '', province: '', millercheck: false, insta: false}}
                  validate={values => {
                      const errors = {};
                      if (!values.email) {
                          errors.email = 'Required field';
                      } else if (
                          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                      ) {
                          errors.email = 'Invalid email address';
                      }
                      values.userpin = Pin;
                      if (!values.firstname) { errors.firstname = 'Required field'; }
                      if (!values.lastname) { errors.lastname = 'Required field'; }
                      if (!values.address1) { errors.address1 = 'Required field'; }
                      if (!values.postalcode) { errors.postalcode = 'Required field'; }
                      if (!values.city) { errors.city = 'Required field'; }
                      return errors;
                  }}
                  onSubmit={(values, { setSubmitting }) => {
  setSubmitting(false);
                          this.props.history.push({ pathname:'/Skillpage', data: values });


                  }}
              >
                  {({ isSubmitting }) => (

                            <Form className="form form--shippingForm ">
                               
                            <h1 className="form--shippingForm__header">Shipping Address</h1>
                                
                            <p className="field required form--shippingForm__firstName">
                              <ErrorMessage name="firstname" component="div" className="fieldError"/>
                              <Field type="text" name="firstname" placeholder="First Name"/>
                            </p>

                            <p className="field required form--shippingForm__lastName">
                              <ErrorMessage name="lastname" component="div" className="fieldError"/>
                              <Field type="text" name="lastname" placeholder="Last Name"/>
                            </p>

                            <span className="form--shippingForm__poNotice">Note: PO Boxes are not accepted.</span>

                            <p className="field required form--shippingForm__address">
                              <ErrorMessage name="address1" component="div" className="fieldError"/>
                              <Field type="text" name="address1" placeholder="Address"/>
                            </p>

                            <p className="field required form--shippingForm__city">
                              <ErrorMessage name="city" component="div" className="fieldError"/>
                              <Field type="text" name="city" placeholder="City"/>
                            </p>

                            <p className="field required form--shippingForm__province">
                              <ErrorMessage name="province" component="div" className="fieldError"/>
                              <Field as="select" placeholder='province' name='province' required>
                                <option value="" selected disabled hidden>Province</option>
                                <option value="AB">Alberta</option>
                                <option value="BC">British Columbia</option>
                                <option value="MB">Manitoba</option>
                                        <option value="NB">New Brunswick</option>
                                        <option value="NL">Newfoundland and Labrador</option>
                                <option value="NS">Nova Scotia</option>
                                <option value="ON">Ontario</option>
                                <option value="PE">Prince Edward Island</option>
                                <option value="SK">Saskatchewan</option>

                              </Field>
                            </p>

                            <p className="field required form--shippingForm__postal">
                              <ErrorMessage name="postalcode" component="div" className="fieldError"/>
                              <Field type="text" name="postalcode" placeholder="Postal Code"/>
                            </p>

                            <h2 className="form--shippingForm__header form--shippingForm__header--contact">Contact Info</h2>

                            <span className="form--shippingForm__emailNotice">Used to notify successful contest entry, and to inform Shipping Confirmation of Winners Only</span>

                            <p className="field required form--shippingForm__email">
                                <ErrorMessage name="email" component="div" className="fieldError"/>
                                <Field type="email" name="email" placeholder="Email Adress"/>
                            </p>
                                <p className="field required form--checkbox">
                                    <Field type="checkbox" name="millercheck" /><span>Yes, I would like to receive messages about sweepstakes, events and other fun stuff from MILLER LITE & its affiliates. I acknowledge my data may leave Canada for processing, storage.</span>
                                </p>
                                
                            <p className="field form--shippingForm__btn">
                              <button className="btn hvr-push" type="submit" disabled={isSubmitting}>Submit</button>
                            </p>

                          </Form>
                  )}
              </Formik>

            </div>

                <div className="FooterLogos">
                    <div className="MLB"><img className="MLB_Img" src={MLB} /></div>
                    <div className="Rawlings"><img className="Rawlings_Img" src={Rawlings} /></div>
                </div>
                <footer className="footer">
                    <div className="disclaimertext"> *Must be legal drinking age. No purchase necessary. Skill-Testing Question Applies. Five (5) Prizes (1 x Miller Lite custom catcher's glove) avail. to be won, each worth approx. $300.00 CAD. Contest ends 12:00pm (ET) on July 19, 2021 ("Entry Deadline"). Visit www.millerlite.ca/reliefcatcher for contest details</div><div className="disclaimertext">™/© 2021 MLB. </div>
                    <div className="footerContents">

                        <img src={celebrate} className="celebrate" alt="celebrate responsibly" />
                        <div className="footertext"> ® 2021 Molson Coors Brewing Company</div>

                        <ul className="footerLinks">

                            <li className="footerLinks__item">
                                <a href="/TermsPage">Terms & Conditions</a>
                            </li>
                            <li className="footerLinks__item">
                                <a href="/FAQ">FAQ</a>
                            </li>


                            <li className="footerLinks__item">
                                <a href="/ContactPage">Contact Us</a>
                            </li>

                        </ul>

                        <ul className="footerSocials">

                            <li className="footerSocials__item">
                                <a href="https://www.facebook.com/millerliteca/" target="_blank">
                                    <img src={facebook} />
                                </a>
                            </li>

                            <li className="footerSocials__item">
                                <a href="https://twitter.com/MillerLiteCA" target="_blank">
                                    <img src={twitter} />
                                </a>
                            </li>

                            <li className="footerSocials__item">
                                <a href="https://www.instagram.com/millerliteca/?hl=en" target="_blank">
                                    <img src={instagram} />
                                </a>
                            </li>

                        </ul>

                    </div>

                </footer>  

          </div>

        )

    };
}
